import * as React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import PricingBanner from '../components/PricingBanner'
import Layout from '../components/Layout'
import Seo from "../components/Seo"

const PricingPage = () => {
    const data = useStaticQuery(graphql`
        query {
            BadgeStripeClimate: file(relativePath: { eq: "badge-stripe-climate.svg" }) {
                publicURL
            },
        }
    `);

    return (
        <Layout>
            <Seo 
                title="Pricing"
                description="Fair price for every ride"
            />
            <div className='flex flex-col items-center justify-center text-center'>
                <h1 className="my-8 text-4xl font-bold md:my-12 text-rickshaw-blue">
                    Fair price for every ride
                </h1>
            </div>

            <section className="flex flex-col w-full px-8 mx-auto text-center lg:px-0 lg:flex-row lg:max-w-screen-lg">
                <article className="flex flex-col px-4 py-8 mb-8 align-middle rounded-lg shadow-xl lg:mb-0 lg:mr-6 lg:w-1/2 justify-items-center">
                    <h2 className="font-semibold uppercase text-rickshaw-blue">Free</h2>
                    <p className="mt-6 text-7xl text-rickshaw-blue">$0</p>
                    <p className="mt-4 text-rickshaw-blue">per month</p>
                    <a className="p-4 my-4 font-semibold text-white rounded-md bg-rickshaw-blue" href="https://confirmsubscription.com/h/d/7C7BF2C5C5C65206">Early access</a>
                    <ul className="px-6 text-gray-500">
                        <li className="mb-1">Manual trigger</li>
                        <li className="mb-1">1000 automated events</li>
                        <li className="mb-1"></li>
                    </ul>
                </article>
                <article className="flex flex-col px-4 py-8 mb-8 align-middle rounded-lg shadow-xl lg:mb-0 lg:mr-6 lg:w-1/2 justify-items-center">
                    <h2 className="font-semibold uppercase text-rickshaw-blue">Basic</h2>
                    <p className="mt-6 text-7xl text-rickshaw-blue">$10</p>
                    <p className="mt-4 text-rickshaw-blue">per month</p>
                    <a className="p-4 my-4 font-semibold text-white rounded-md bg-rickshaw-blue" href="https://confirmsubscription.com/h/d/7C7BF2C5C5C65206">Early access</a>
                    <ul className="px-6 text-gray-500">
                        <li className="mb-1">Unlimited manual triggers</li>
                        <li className="mb-1"><b>10,000</b> monthly events</li>
                        <li className="mb-1"><b>0.00010c</b> / extra event</li>
                        <li className="mb-1">Unlimited reporting</li>
                    </ul>
                </article>
                <article className="flex flex-col px-4 py-8 align-middle rounded-lg shadow-xl lg:w-1/2">
                    <h2 className="font-semibold uppercase text-rickshaw-blue">Premium</h2>
                    <p className="mt-6 text-7xl text-rickshaw-blue">$15</p>
                    <p className="mt-4 text-rickshaw-blue">per month</p>
                    <a className="p-4 my-4 font-semibold text-white rounded-md bg-rickshaw-blue" href="https://confirmsubscription.com/h/d/7C7BF2C5C5C65206">Early access</a>
                    <ul className="px-6 text-gray-500">
                    <li className="mb-1">Unlimited manual triggers</li>
                        <li className="mb-1"><b>20,000</b> monthly events</li>
                        <li className="mb-1"><b>0.00005c</b> / extra event</li>
                        <li className="mb-1">Unlimited reporting</li>
                    </ul>
                </article>
            </section>

            <section className="flex flex-col items-center mx-4 mt-14 md:mx-auto lg:max-w-screen-xl">
                <img src={data.BadgeStripeClimate.publicURL} className="w-16" alt="Stripe Climate"/>
                <p className="py-4 text-center">Rickshaw will contribute <b>1% of your spend</b> to remove CO₂ from the atmosphere.</p>
                <a className="flex items-center text-base text-gray-500" href="https://www.stripe.com/climate/">
                    Learn about Stripe Climate
                    <svg className="w-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                </a>
            </section>
            <PricingBanner />
        </Layout>
    );
};

export default PricingPage;
